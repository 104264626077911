<template>
  <div
    class="jexy-file-input"
  >
    <j-graphic
      v-if="inputPreview"
      :src="inputPreview"
      max-height="150px"
    >

    <v-row no-gutters class="mx-4 file-input-row">
      <v-col cols="4" align-self="center">
        <!-- Mode Toggle -->
        <v-btn-toggle
          v-model="widgetMode"
          mandatory
          class="ml-2"
        >
          <v-btn small title="Upload from your computer" @click="resetUrlField">
            <j-icon icon="paperclip" />
          </v-btn>
          <v-btn small title="Upload from URL" @click="resetField">
            <j-icon icon="link" />
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="8">
        <!-- File Input -->
        <v-file-input
          v-if="widgetMode == 0"
          v-bind="control"
          prepend-icon=""
          full-width
          dense
          small-chips
          single-line
          :clearable="false"
          @change="addFile"
          @click.clear="resetField"
          class="mr-2 file-input"
        />
        <!-- URL Input -->
        <v-text-field
          v-else
          v-bind="urlControl"
          dense
          single-line
          @change="addUrl"
          class="mr-2 jexy-string"
        />
      </v-col>
    </v-row>
    
    </j-graphic>
  </div>
</template>

<script>

export default {
  name: 'File',
  inject: ['model', 'field'],
  props: {
    preview: {
      type: Boolean,
      default: true
    },
    height: {
      type: [Number, String],
      default: null
    },
    mode: {
      type: String,
      default: 'file'
    }
  },
  data () {
    return {
      originalUrl: this.model[this.field],
      widgetMode: this.mode == 'file' ? 0 : 1
    }
  },
  computed: {
    urlField () {
      return `remote_${this.field}_url`
    },
    control () {
      return this.model.control(this.field, this.$attrs)
    },
    urlControl () {
      return {
        ...this.model.control(this.urlField, this.$attrs),
        label: `${this.control.label} (URL)`
      }
    },
    inputPreview () {
      if (this.preview === false) {
        return false
      } else if (this.model[this.urlField]) {
        return this.model[this.urlField]
      } else if (typeof this.model.display(this.field) == 'string' && this.model.display(this.field)) {
        // Vuetify can't handle promises / observers
        return this.model.display(this.field)
      } else {
        return '/assets/img/missing-image.png'
      }
    }
  },
  methods: {
    resetField () {
      this.model.set(this.field, this.originalUrl)
    },
    resetUrlField () {
      this.model.set(this.urlField, '')
    },
    addFile (file) {
      this.model.addUpload(this.field, file)
    },
    addUrl (url) {
      this.model.set(this.urlField, url)
    }
  }
}
</script>

<style lang="scss">
  
.file-input-row {
  background-color: rgba(255, 255, 255, 0.75);

  .v-chip {
    @extend %j-label;
  }
  
}
</style>